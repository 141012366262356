import { gtmUltils } from "@utils";
import { FC } from "react";
import styled from "styled-components";
import { UrlObject } from "url";
import { useFormatCmsUrl } from "utils/hooks";
import { combineClassName } from "utils/styled";
import NextLink from "next/link";
import omit from "lodash/omit";
import { fwdColors } from "config/fwd-colors";

const StyledLink = styled.a<{ disabled?: boolean }>`
  display: block;
  ${(p) =>
    p.disabled &&
    `
    cursor: not-allowed;
  `}
`;

const Wrapper = styled.div`
  :hover {
    color: ${fwdColors.orange};
  }
`;

export interface LinkProps {
  gaEventLabel?: string;
  gaEvent?: string;
  gtmComponentName?: string;
  openInNewTab?: boolean;
  className?: string;
  href?: string | UrlObject;
  onClick?: any;
  nextLink?: boolean;
  keepRenderWhileHrefEmpty?: boolean;
  disabled?: boolean;
}

export const Link: FC<LinkProps> = (props) => {
  const {
    children,
    href,
    gaEventLabel,
    gtmComponentName,
    onClick,
    openInNewTab,
    className,
    nextLink = false,
    disabled,
    keepRenderWhileHrefEmpty,
    gaEvent,
    ...restProps
  } = props;
  const formatCmsUrl = useFormatCmsUrl();

  const processedHref = formatCmsUrl(href as string);

  const handleLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    gtmComponentName &&
      gtmUltils.buttonClick({
        buttonTitle: gaEvent || gaEventLabel,
        gaEvent: gaEvent,
        destinationUrl: processedHref,
        componentName: gtmComponentName,
      });

    onClick?.(e);
  };

  const sharedLinkProps = {
    ...restProps,
    className: combineClassName(className, "link-tag"),
    onClick: handleLinkClick,
    target: openInNewTab ? "_blank" : "_self",
  };

  if (!href) {
    if (keepRenderWhileHrefEmpty) {
      return <Wrapper {...omit(sharedLinkProps, "target")}>{children}</Wrapper>;
    }
    return null;
  }

  if (nextLink) {
    return (
      <NextLink href={processedHref} passHref>
        <StyledLink {...(sharedLinkProps as any)}>{children}</StyledLink>
      </NextLink>
    );
  }
  return (
    <StyledLink
      {...(sharedLinkProps as any)}
      disabled={disabled}
      href={processedHref}
    >
      {children}
    </StyledLink>
  );
};
