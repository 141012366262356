import React, { FunctionComponent, useMemo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import isNumber from "lodash/isNumber";
import Slick, { Settings } from "react-slick";
import { NamedIcon, fwdColors } from "@fwd-dep/nextgen-ui-lib";
import { applyWhenHoverSupported } from "utils/styled";
import { breakpoint } from "@d2c-ui-components-react";
import Skeleton from "@material-ui/lab/Skeleton";
import { breakpointList } from "@d2c-ui-components-react";
import { useIsHydrating } from "hooks/components/useHydrating";

const ARROW_BUTTON_SIZE = 40;
const ARROW_BUTTON_PADDING = 16;
const SKELETON_HORIZONTAL_MARGIN = 16;
const OVERFLOW_PADDING = ARROW_BUTTON_SIZE + ARROW_BUTTON_PADDING + 20;

const notOverflowArrowButton = css`
  overflow: hidden;
  ${breakpoint("sm")`
    padding: 0 ${OVERFLOW_PADDING}px;
  `}
`;

const hidePaginationDotsOnMobile = css`
  .slick-dots {
    display: none !important;

    ${breakpoint("sm")`
      display: block !important;
    `};
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0;
`;

const SwiperWrapper = styled.div<{
  overflowArrowButton?: boolean;
  showPaginationDotsOnMobile?: boolean;
  hide?: boolean;
  backgroundColor?: string;
}>`
  opacity: ${(p) => (p.hide ? 0 : 1)};
  margin: 0px -16px;

  ${(p) => !p.overflowArrowButton && notOverflowArrowButton}
  & > div.slick-slider {
    .slick-list {
      overflow: hidden;
    }

    .slick-dots {
      position: relative;
      bottom: -16px;
      left: 0;
      right: 0;

      li {
        width: auto;
        margin: 0 4px;
        > div {
          ${(p) =>
            p.backgroundColor === fwdColors.orange
              ? `background-color: #d3d3d3;`
              : ""}
        }
        &.slick-active > div {
          width: 24px;
          height: 6px;
          border-radius: 10px;
          background: ${(p) =>
            p.backgroundColor === fwdColors.orange
              ? fwdColors.white
              : fwdColors.orange};
          opacity: 1;
        }
      }
    }
    &.active-end {
      .slick-dots {
        li:last-child {
          div {
            width: 24px;
            height: 6px;
            border-radius: 10px;
            background: ${(p) =>
              p.backgroundColor === fwdColors.orange
                ? fwdColors.white
                : fwdColors.orange};
            opacity: 1;
          }
        }
      }
    }
    ${(p) => !p.showPaginationDotsOnMobile && hidePaginationDotsOnMobile}
  }
`;

const NavigationButton = styled.div<{ oldArrowStyle?: boolean }>`
  display: none !important;
  align-items: center;
  justify-content: center;
  background: ${fwdColors.white} !important;
  width: ${ARROW_BUTTON_SIZE}px !important;
  height: ${ARROW_BUTTON_SIZE}px !important;
  border-radius: ${(p) => (p.oldArrowStyle ? "4px" : "50%")} !important;
  cursor: pointer;

  color: ${fwdColors.orange} !important;
  ${(p) =>
    !p.oldArrowStyle &&
    `  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));`}

  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:before {
    display: none;
  }

  &.slick-disabled {
    display: none !important;
  }

  :hover {
    ${(p) =>
      p.oldArrowStyle
        ? applyWhenHoverSupported(`
        background: rgba(232, 119, 34, 0.2) !important;
      `)
        : applyWhenHoverSupported(`
        background: ${fwdColors.orange} !important;
        color: ${fwdColors.white} !important;
      `)}
  }

  > svg {
    width: 25px !important;
    height: 25px !important;
  }

  ${breakpoint("sm")`
    display: flex !important;
  `}

  > svg.MuiSvgIcon-root:hover {
    background-color: transparent !important;
  }
`;

const ButtonNext = styled(NavigationButton)<{
  overflowArrowButton?: boolean;
}>`
  right: -${ARROW_BUTTON_SIZE + 16}px !important;
`;

const ButtonPrev = styled(NavigationButton)<{
  overflowArrowButton?: boolean;
}>`
  left: -${ARROW_BUTTON_SIZE + 16}px !important;
`;

const calculateCardNumbers = (
  configs: { slidesToShow: number; breakpoint: number | null }[] | number
) => {
  if (isNumber(configs)) {
    return `width: calc((100% / ${configs}) - ${SKELETON_HORIZONTAL_MARGIN}px);`;
  }
  return configs.map((config) => {
    const width = `width: calc((100% / ${config.slidesToShow}) - ${SKELETON_HORIZONTAL_MARGIN}px);`;

    if (config.breakpoint === null) {
      return width;
    }

    return `@media screen and (max-width: ${config.breakpoint}px){
      ${width}
    }`;
  });
};

const SkeletonWrapper = styled.div<{
  overflowArrowButton?: boolean;
}>`
  width: ${(p) =>
    p.overflowArrowButton ? "100%" : `calc(100% - ${OVERFLOW_PADDING * 2}px)`};
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;

  @media screen and (max-width: ${breakpointList.sm}px) {
    width: 100%;
  }
`;

const SkeletonPadding = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const SkeletonCard = styled.div<{
  cardNumberConfigs: { slidesToShow; breakpoint }[] | number;
}>`
  height: calc(100% - 36px);
  display: inline-block;
  margin: 0 ${SKELETON_HORIZONTAL_MARGIN / 2}px;

  ${(p) => calculateCardNumbers(p.cardNumberConfigs)}
`;

const StyledSkeleton = styled(Skeleton)`
  border-radius: 16px;
`;

const PaginationDot = styled.div<{ isActive?: boolean }>`
  margin: 0 auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${fwdColors.darkGreen};
  opacity: 0.5;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export interface FwdSwiperProps extends Settings {
  children?: any[];
  overflowArrowButton?: boolean;
  hideNavButton?: boolean;
  oldArrowStyle?: boolean;
  showPaginationDotsOnMobile?: boolean;
  spaceBetween?: number;
  defaultStyle?: number;
  skeleton?: JSX.Element;
  skeletonWidth?: string;
  backgroundColor?: string;
  sliderRef?: any;
  enableApi?: boolean;
  isLoading?: boolean;
}

const Slide = styled.div<{ padding?: number }>`
  padding: 10px ${(p) => Number(p.padding || 0) / 2 || 12}px;
`;

const defaultSkeleton = (
  <StyledSkeleton
    animation="wave"
    variant="rect"
    width={"100%"}
    height="100%"
  />
);

export interface FwdSwiperItemProps {
  spacing?: number;
  children?: React.ReactNode;
}

export const FwdSwiperItem: FunctionComponent<FwdSwiperItemProps> = (
  props: any
) => {
  const { spacing = 0, children = "" } = props;
  return (
    <Slide padding={spacing} className="slide-wrapper">
      {children}
    </Slide>
  );
};

export const FwdSwiper: FunctionComponent<FwdSwiperProps> = (props) => {
  const {
    children,
    overflowArrowButton,
    hideNavButton = false,
    oldArrowStyle = false,
    showPaginationDotsOnMobile = true,
    spaceBetween,
    defaultStyle,
    skeletonWidth = "300px",
    skeleton = defaultSkeleton,
    // backgroundColor here is using for detect color of dots
    backgroundColor,
    enableApi = false,
    isLoading = false,
    ...slickProps
  } = props;

  const isHydrating = useIsHydrating();
  const isMobile = useMediaQuery(`(max-width: ${breakpointList.md}px)`);
  const [slideIndex, setSlideIndex] = useState(0);

  const overflowArrow =
    overflowArrowButton || hideNavButton || defaultStyle === 1;

  const arrowButtonProps = {
    oldArrowStyle,
  };

  const skeletonBreakpoint = useMemo(
    () => [
      {
        breakpoint: null,
        slidesToShow: slickProps.slidesToShow,
      },
      ...(
        slickProps.responsive?.map((x) => ({
          breakpoint: x.breakpoint,
          slidesToShow: (x.settings as any).slidesToShow,
        })) || []
      ).filter((x) => !!x.slidesToShow),
    ],
    []
  );

  const sliderSettings = useMemo(() => {
    return {
      dots: true,
      infinite: false,
      useTransform: false,
      adaptiveHeight: true,
      nextArrow: (
        <ButtonNext {...arrowButtonProps}>
          <NamedIcon icon="chevron-right" />
        </ButtonNext>
      ),
      prevArrow: (
        <ButtonPrev {...arrowButtonProps}>
          <NamedIcon icon="chevron-left" />
        </ButtonPrev>
      ),
      arrows: !hideNavButton && !isMobile,
      customPaging: () => {
        return <PaginationDot />;
      },
      beforeChange: (current, next) => {
        if (isMobile) {
          setSlideIndex(Math.round(next) + 1);
        }
      },
      ...slickProps,
    };
  }, [slickProps, isMobile]);

  return (
    <Wrapper>
      <SwiperWrapper
        hide={isHydrating || (enableApi && isLoading)}
        className="fwd-swiper-wrapper"
        overflowArrowButton={overflowArrow}
        showPaginationDotsOnMobile={showPaginationDotsOnMobile}
        backgroundColor={backgroundColor}
      >
        <Slick
          {...sliderSettings}
          ref={sliderSettings.sliderRef}
          className={slideIndex === children?.length ? "active-end" : ""}
        >
          {children}
        </Slick>
      </SwiperWrapper>
      {(isHydrating || (enableApi && isLoading)) && (
        <SkeletonPadding>
          <SkeletonWrapper overflowArrowButton={overflowArrow}>
            {children?.map((item: any, index: number) => (
              <SkeletonCard
                key={`skeleton-item-${index}`}
                cardNumberConfigs={skeletonBreakpoint}
              >
                {skeleton}
              </SkeletonCard>
            ))}
          </SkeletonWrapper>
        </SkeletonPadding>
      )}
    </Wrapper>
  );
};
